@import '~antd/dist/antd.css';
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.login-error {
  font-size: 12px;
  color: red;
}

#components-layout-demo-side .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.location_map-maker-icon {
  color: #FD6C6C;
}

.location_github-icon {
  font-size: 24px;
}

.location_github-link {
  color: #262626;
  font-size: 20px;
}

.location_github-link:hover,
.location_github-link:active {
  color: #4078c0;
  text-decoration: none;
}

.location_suggestion-icon {
  margin-right: 8px;
}

.location_search-bar-container {  
  max-width: 500px;  
}

.location_search-input-container {
  position: relative;
}

.location_search-input,
.location_search-input:focus,
.location_search-input:active {
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);
  border: honeydew;
  display: block;
  width: 100%;
  padding: 16px;
  font-size: 16px;
  border-radius: 2px;
  outline: none;
}

.location_clear-button,
.location_clear-button:active,
.location_clear-button:focus {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  outline: none;
  font-weight: 600;
  color: #999;
}

.location_autocomplete-container {
  border-bottom: honeydew;
  border-left: honeydew;
  border-right: honeydew;
  border-top: 1px solid #e6e6e6;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  border-radius: 0 0 2px 2px;
}

.location_suggestion-item {
  padding: 8px;
  text-align: left;
  background-color: #fff;
  cursor: pointer;
}

.location_suggestion-item--active {
  background-color: #fafafa;
}

.location_dropdown-footer {
  display: flex;
  justify-content: flex-end;
  padding: 4px;
}

.location_dropdown-footer-image {
  display: inline-block;
  width: 150px;
}

.location_spinner {
  color: #18bc9c;
  font-size: 30px;
}

.location_error-message {
  color: red;
}

.location_geocode-result-header {
  font-size: 20px;
  color: #222222;
}

@media (max-width: 480px) {
  .autocomplete-container {
    text-align: left;
  }
}
.fa {
  font-size: 29px;
}

@media (min-width: 768px) {
  .alert {
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    -webkit-animation: fadein .75s;
    -moz-animation: fadein .75s;
    -ms-animation: fadein .75s;
    -o-animation: fadein .75s;
    animation: fadein .75s;
  }
}

.modal-dialog-big {
    max-width: 750px !important;
}
.modal-dialog-medium {
  max-width: 625px !important;
}

.pagination{
	display: flex;
    justify-content: center;
}
.pagination li{
	padding: 3px;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid #3167C3;;
    margin: 5px;
}
.pagination .previous:hover{
	color: #3167C3;
}
.pagination .next:hover{
	color: #3167C3;
}
.pagination .active{
    background: #3167C3;
    color: #E6E9EE;	
}

