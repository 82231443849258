@import url(https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800);
* {
  box-sizing: border-box;
}
body {
  background: #1C8EF9 !important;
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: 'Fira Sans', sans-serif;
}

h1,h2,h3,h4,h5,h6,label,span {
  font-weight: 500;
  font-family: 'Fira Sans', sans-serif;
}

body, html, .App, #root, .auth-wrapper {
  width: 100%;
  height: 100%;
}

.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.auth-inner {
  width: 410px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all .3s;
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}

#loadingContent{
	display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 24px;
  width: 15% !important;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.login-error {
  font-size: 12px;
  color: red;
}

#components-layout-demo-side .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.location_map-maker-icon {
  color: #FD6C6C;
}

.location_github-icon {
  font-size: 24px;
}

.location_github-link {
  color: #262626;
  font-size: 20px;
}

.location_github-link:hover,
.location_github-link:active {
  color: #4078c0;
  text-decoration: none;
}

.location_suggestion-icon {
  margin-right: 8px;
}

.location_search-bar-container {  
  max-width: 500px;  
}

.location_search-input-container {
  position: relative;
}

.location_search-input,
.location_search-input:focus,
.location_search-input:active {
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);
  border: honeydew;
  display: block;
  width: 100%;
  padding: 16px;
  font-size: 16px;
  border-radius: 2px;
  outline: none;
}

.location_clear-button,
.location_clear-button:active,
.location_clear-button:focus {
  position: absolute;
  right: 0px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background: transparent;
  border: none;
  outline: none;
  font-weight: 600;
  color: #999;
}

.location_autocomplete-container {
  border-bottom: honeydew;
  border-left: honeydew;
  border-right: honeydew;
  border-top: 1px solid #e6e6e6;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  border-radius: 0 0 2px 2px;
}

.location_suggestion-item {
  padding: 8px;
  text-align: left;
  background-color: #fff;
  cursor: pointer;
}

.location_suggestion-item--active {
  background-color: #fafafa;
}

.location_dropdown-footer {
  display: flex;
  justify-content: flex-end;
  padding: 4px;
}

.location_dropdown-footer-image {
  display: inline-block;
  width: 150px;
}

.location_spinner {
  color: #18bc9c;
  font-size: 30px;
}

.location_error-message {
  color: red;
}

.location_geocode-result-header {
  font-size: 20px;
  color: #222222;
}

@media (max-width: 480px) {
  .autocomplete-container {
    text-align: left;
  }
}
.fa {
  font-size: 29px;
}

@media (min-width: 768px) {
  .alert {
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    -webkit-animation: fadein .75s;
    animation: fadein .75s;
  }
}

.modal-dialog-big {
    max-width: 750px !important;
}
.modal-dialog-medium {
  max-width: 625px !important;
}

.pagination{
	display: flex;
    justify-content: center;
}
.pagination li{
	padding: 3px;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid #3167C3;;
    margin: 5px;
}
.pagination .previous:hover{
	color: #3167C3;
}
.pagination .next:hover{
	color: #3167C3;
}
.pagination .active{
    background: #3167C3;
    color: #E6E9EE;	
}


.toolTipinfo {
    background-color: #FFCC33 !important;
    color: black !important;
    border-radius: 8px !important;
    font-size: 16px !important;
    text-align-last: start;
    font-weight: normal;
    letter-spacing: 0.02em;
  }
